.homeContainer {
    width: 100vw;
    height: 85vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-flow: wrap;
    overflow: auto;
    animation: dispPage 1s linear;
}
:root {
    --widthContainer: 300px;
    --heightContainer: 460px;
    --cardFontSize: 14px;
    --widthImage: 200px;
    --titleFont: 22px;
}
@media screen and (max-width: 1000px) {
    :root {
        --widthContainer: 240px;
        --heightContainer: 400px;
        --cardFontSize: 12px;
        --widthImage: 120px;
        --titleFont: 16px;
    }
}
.cardContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-image: repeating-linear-gradient(45deg, rgba(0,0,0,0.05) 0px, rgba(0,0,0,0.05) 2px,transparent 2px, transparent 4px),linear-gradient(0deg, rgb(165,198,244),rgb(232,254,240));
    width: var(--widthContainer);
    height: var(--heightContainer);
    border: 1px solid lightgrey;
    border-radius: 8px;
    box-shadow: 5px 5px rgb(175, 175, 175);
    transition: 0.25s;
    font-family: 'Montserrat', sans-serif;
    margin: 25px 20px;
    animation: showCard 0.6s ease-in-out;
}
@keyframes showCard {
    0%{transform: scale(0);}
    70%{transform: scale(1.05);}
    100%{transform: scale(1);}
}
.cardTitle {
    font-size: var(--titleFont);
}
.cardContainer:hover {
    cursor: pointer;
    filter: brightness(105%);
    box-shadow: 8px 8px rgb(175, 175, 175);
}
.cardDescription {
    margin: 0 12px;
    text-align: justify;
    font-size: var(--cardFontSize);
    line-height: 1.4em;
}
.cardImage, .cardImage > img {
    width: var(--widthImage);
}
.animClown {
    animation: animClownHead 15s ease-out infinite;
}
@keyframes animClownHead {
    0%{transform: rotate(3deg) scale(1);}
    95%{transform: rotate(3deg) scale(1);}
    96%{transform: rotate(-7deg) scale(1.1);}
    99%{transform: rotate(13deg) scale(1.1);}
    100%{transform: rotate(3deg) scale(1);}
}