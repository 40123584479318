:root {
    --reactLogoWidth: 300px;
    --reactLogoScale: 1;
    --reactLogoHeight: 300px;
    --lvTextFontSize: 18px;
    --lvH2FontSize: 26px;
}
@media screen and (min-width: 700px) and (max-width: 1000px) {
    :root {
        --reactLogoWidth: 180px;
        --reactLogoScale: 1;
        --reactLogoHeight: 300px;
        --lvTextFontSize: 16px;
        --lvH2FontSize: 18px;
    }
}
@media screen and (max-width: 700px) {
    :root {
        --reactLogoWidth: 180px;
        --reactLogoScale: 0.6;
        --reactLogoHeight: 150px;
        --lvTextFontSize: 14px;
        --lvH2FontSize: 16px;
    }
}
.reactLogoAnimate {
    transform: scale(var(--reactLogoScale));
    max-height: var(--reactLogoHeight);
}
.reactLogoAnimate > div > svg {
    animation: logoTurnOn 15s linear infinite;
}
@keyframes logoTurnOn {
    0% {transform: rotate(0);}
    100% {transform: rotate(360deg);}
}
.reactLogoAnimate {
    min-width: var(--reactLogoWidth);
}
.lvH2Title {
    text-align: justify;
    font-family: 'Montserrat', sans-serif;
    font-size: var(--lvH2FontSize);
    padding: 10px;
}
.pageText {
    max-width: 1000px;
    text-align: justify;
    font-family: 'Montserrat', sans-serif;
    font-size: var(--lvTextFontSize);
    padding: 10px;
    line-height: 1.6em;
}
.pageTextCode {
    max-width: 1000px;
    text-align: justify;
    font-family: 'Montserrat', sans-serif;
    font-size: var(--lvTextFontSize);
    padding: 10px;
    line-height: 1.4em;
    background: rgb(4, 5, 73);
    color: lightblue;
}
code {
    background: rgb(4, 5, 73);
    color: lightblue;
    margin: 2px 4px;
}
.pageText_2 {
    padding: 40px;
}
.logigramme_img {
    width: 700px;
    max-width: 90vw;
    border: 1px solid grey;
}
.folder_img {
    width: 200px;
    padding: 20px;
}