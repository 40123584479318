@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@media screen and (min-width: 1050px) {
  * {
      --menuFontSize: 16px;
      --titleFontSize: 20px;
      --rightSelect: 7vw;
  }
}
@media screen and (min-width: 900px) and (max-width: 1050px) {
  * {
      --menuFontSize: 12px;
      --titleFontSize: 18px;
      --rightSelect: 6vw;
  }
}
@media screen and (min-width: 500px) and (max-width: 900px) {
  * {
      --menuFontSize: 14px;
      --titleFontSize: 12px;
      --rightSelect: 5vw;
  }
}
@media screen and (max-width: 500px) {
  * {
      --menuFontSize: 12px;
      --titleFontSize: 8px;
      --rightSelect: 4vw;
  }
  h1 {
    padding-left: 45px;
  }
}
body {
  position: fixed;
  z-index: 100;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 94vh;
}
@keyframes dispPage {
    0%{opacity: 0;}
    100%{opacity: 1;}
}
.background_app {
  z-index: -1;
  position: absolute;
  top: -5vh;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transform: scale(1.25);
  background-image: url('deuxieme_plan.png');
  background-size: cover;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.disp {
  display: content;
}
.mask {
  display: none;
}
.centrCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.centrCenterColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.col_flex_aro {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.row_flex_aro {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.centrAround {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
/* **************** header **************** */
* {
  --photoContainerWidth: 88px;
  --photoContainerHeight: 88px;
  --photoMenuDeployed: 250px;
}
@media screen and (max-width: 900px) {
  * {
    --photoContainerWidth: 60px;
    --photoContainerHeight: 60px;
    --photoMenuDeployed: 200px;
  }
}
.headerPhotoContainer {
  position: absolute; top: 1vh; left: 4vw;
  display: flex;
  overflow: hidden;
  width: var(--photoContainerWidth);
  height: var(--photoContainerHeight);
  color: lightgrey;
  transition: .2s;
}
.headerPhotoContainer:hover {
  position: absolute; top: 1vh; left: 4vw;
  display: flex;
  overflow: hidden;
  width: var(--photoMenuDeployed);
  height: var(--photoContainerHeight);
  z-index: 2000;
  background: lightgrey;
  color: black;
  border-radius: 15% 0% 0% 15% / 50% 20% 20% 50%;
}
.headerPhotoLink {
  width: var(--photoContainerWidth);
  height: var(--photoContainerHeight);
}
.headerPhoto {
  width: var(--photoContainerWidth);
  height: var(--photoContainerHeight);
  border-radius: 50%;
  transition: .2s;
}
.headerPhoto:hover {
  cursor: pointer;
  transform: scale(1.02);
}
.profilLink {
  width: 100%;
  height: var(--photoContainerHeight);
  min-height: 40px;
  max-height: var(--photoContainerHeight);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.profilLinkTitle {
  margin: 5px 0;
  font-size: 16px;
  font-weight: 700;
}
.profilLinkText1, .profilLinkText2 {
  margin: 3px 0;
  font-size: 16px;
  transition: .2s;
}
.profilLinkText1:hover, .profilLinkText2:hover {
  cursor: pointer;
  color: blue;
  font-weight: 700;
  transform: scale(1.05);
}
@media screen and (max-width: 900px) {
  .profilLinkText1, .profilLinkText2 {
      font-size: 12px;
  }
}
.title {
  width: 100%;
  font-size: var(--titleFontSize);
  letter-spacing: 0.1em;
  font-family: 'Alegreya Sans SC', sans-serif;
}
.title2 {
  width: 100%;
  font-size: 20px;
  letter-spacing: 0.1em;
  font-family: 'Alegreya Sans SC', sans-serif;
}
.title > h1 {
  margin-top: 40px;
  margin-right: 5vw;
}
@keyframes showH1Title {
  0%{transform: translateY(-15vh); opacity: 0;}
  100%{transform: translateY(0); opacity: 1;}
}
.selectOther {
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(rgb(155, 190, 207), rgb(127, 163, 218), rgb(155, 190, 207));
  font-size: var(--menuFontSize);
  --calcHeight: calc(var(--menuFontSize) * 2);
  height: var(--calcHeight);
  overflow: hidden;
  position: absolute;
  top: 10px; right: var(--rightSelect);
  border: 1px solid rgb(255, 255, 118);
  border-radius: 6px;
  transition: 0.4s;
}
.selectOther:hover {
  cursor: pointer;
  height: calc(var(--calcHeight) * 9);
  z-index: 1000;
}
.link {
  padding: 0 8px;
  text-decoration: none;
  color: black;
  line-height: 2em;
  width: 100%;
  text-align: center;
}
.link:hover {
  font-weight: 700;
  color: rgb(28, 34, 110);
}
option {
  background: rgb(187, 200, 219);
}
header {
  position: relative;
} 