@media screen and (min-width: 1280px) {
    :root {
        --imageWidth: 15vw;
        --imageTopPosition: 30px;
        --imageLeftPosition: 4vw;
        --sectionHorizontalMargin: 6vw;
        --h2FontSize: 22px;
        --h3FontSize: 20px;
        --pFontSize: 16px;
        --pHorizontalMargin: 5vw;
    }
}
@media screen and (min-width: 1000px) and (max-width: 1280px) {
    :root {
        --imageWidth: 12vw;
        --imageTopPosition: 4vw;
        --imageLeftPosition: 0;
        --sectionHorizontalMargin: 2vw;
        --h2FontSize: 18px;
        --h3FontSize: 16px;
        --pFontSize: 14px;
        --pHorizontalMargin: 5vw;
    }
}
@media screen and (min-width: 600px) and (max-width: 1000px) {
    :root {
        --imageWidth: 10vw;
        --imageTopPosition: 4vw;
        --imageLeftPosition: 0;
        --sectionHorizontalMargin:0;
        --h2FontSize: 16px;
        --h3FontSize: 14px;
        --pFontSize: 12px;
        --pHorizontalMargin: 2vw;
    }
}
@media screen and (max-width: 600px) {
    :root {
        --imageWidth: 0;
        --imageTopPosition: 4vw;
        --imageLeftPosition: 0;
        --sectionHorizontalMargin:0;
        --h2FontSize: 12px;
        --h3FontSize: 11px;
        --pFontSize: 11px;
        --pHorizontalMargin: 2vw;
    }
}

.AVRsection {
    font-family: 'Montserrat', sans-serif;
    height: 82vh;
    overflow: auto;
    background: rgba(250, 250, 250, 0.8);
    border-radius: 12px;
    margin: 0 var(--sectionHorizontalMargin);
    animation: dispPage 0.6s linear;
}
.ttHeader {
    margin-left: 16vw;
}
.ttHeader > h2 {
    font-size: var(--h2FontSize);
}
.ttHeader > h3 {
    font-size: var(--h3FontSize);
}
.ttText {
    margin: 0 var(--pHorizontalMargin);
    text-align: justify;
}
.flash_p {
    line-height: 1.4em;
    font-size: var(--pFontSize);
    margin: 10px var(--pHorizontalMargin);
    text-align: justify;
}
.flash_code {
    line-height: 1.8em;
    font-size: calc(var(--pFontSize) - 3px);
    margin: 10px var(--pHorizontalMargin);
    text-align: justify;
}
.shadowHorizontalSpace {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.insertImageLeft {
    content: var(--displayImage);
    width: var(--imageWidth);
    height: var(--imageWidth);
    margin-right: 2.5vw;
    margin-top: var(--imageTopPosition);
    margin-left: var(--imageLeftPosition);
}
.insertImageLeft > img {
    width: var(--imageWidth);
    max-width: 250px;
    height: var(--imageWidth);
}
.ttList > li {
    line-height: 1.6em;
    margin: 0 var(--pHorizontalMargin);
    font-size: var(--pFontSize);
}
.ttList2 > li {
    line-height: 1.6em;
    margin: 0 var(--pHorizontalMargin);
    font-size: var(--pFontSize);
}
.shadowImageSpace {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* position: relative;
    left: -5vw; */
}
.fullImage {
    width: 30vw;
    height: auto;
    margin-right: 2.5vw;
    margin-top: var(--imageTopPosition);
    margin-left: var(--imageLeftPosition);
}
.fullImage > img {
    width: 30vw;
    max-width: 400px;
    height: auto;
}
.fullImage_2 {
    max-height: 60vh;
    margin-right: 2.5vw;
    margin-top: var(--imageTopPosition);
    margin-left: var(--imageLeftPosition);
    overflow: hidden;
}
.fullImage_2 > img {
    max-height: 40vh;
    max-width: 40vw;
}
.dispCode {border: 1px solid red;
    margin-left: var(--pHorizontalMargin);
    padding: 5px;
    background: rgb(58, 58, 58);
    color: lightblue;
    width: 1000px;
    overflow-x: auto;
    font-size: var(--pFontSize);
}
.AVRmenu {
    position: absolute;
    right: 6vw;
    bottom: 50px;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    background: rgba(200, 200, 200, 0.2);
    border-radius: 50%;
    transition: 0.2s;
}
.AVRmenu:hover {
    background: rgba(200, 200, 200, 1);
    cursor: pointer;
}
.AVRmenu:hover > .arrowMenu {
    color: rgba(255, 255, 224, 1);
}
.AVRmenu:active > .arrowMenu {
    color: yellow;
}
.arrowMenu {
    transform: translateX(5px) rotate(-90deg);
    font-size: 75px;
    color: rgba(255, 255, 224, 0.5);
    animation: 2.6s showArrowMenunBut ease-in-out;
}
@keyframes showArrowMenunBut {
    0% {color: rgba(255, 255, 224, 0.5);}
    30% {color: rgba(255, 255, 6, 1);transform: rotate(-60deg);}
    60% {color: rgba(236, 45, 31, 1);transform: rotate(120deg);}
    90% {color: rgba(236, 45, 31, 1);transform: rotate(270deg);}
    93% {color: rgba(255, 255, 6, 1);}
    96% {color: rgba(236, 45, 31, 1);}
    0% {color: rgba(255, 255, 224, 0.5);}
}
.showAVRMenu {
    transform: scale(0);
    position: absolute;
    right: 2vw;
    bottom: 80px;
    width: 300px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(248, 248, 248);
    border: 2px solid grey;
    box-shadow: 0 0 5px 5px rgb(179, 179, 179);
    border-radius: 10px;
    transition: 0.2s;
}
.livechat_menu {
    left: 2vw;
}
.AVRmenu:hover > .showAVRMenu, .showAVRMenu:hover {
    transform: scale(1);
}
.showAVRMenu > ul {
    list-style: none;
}
.AVRMenuItem {
    color: black;
    font-size: 14px;
    margin-top: 2em;
    text-decoration: none;
    transition: 0.2s;
}
.AVRMenuItem:hover {
    font-weight: 800;
    color: blue;
}
.LC_pseudo_validation {
    outline: none;
    border: 1px solid grey;
    width: 80px;
    text-align: center;
    font-size: 20px;
    margin: 5px 10px;
    background: rgb(99, 248, 99);
    transition: 0.2s;
}
.LC_pseudo_validation:hover {
    cursor: pointer;
    background: rgb(150, 253, 150);
}
.LC_pseudo_validation:active {
    background: rgb(245, 230, 98);
}