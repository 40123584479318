.menuContainer {
    display: flex;
    flex-flow: wrap;
    animation: dispPage 1s linear;
}
@media screen and (max-width: 500px) {
    .menuContainer {
        margin-top: 10px;
    }
}
@media screen and (min-width: 900px) {
    .demiMenu {
        width: 50vw;
    }
}
@media screen and (max-width: 900px) {
    .demiMenu {
        margin: 6px 0;
        width: 100vw;
    }
}
.menu_1ButShadow {
    background: rgb(110, 110, 110);
    border-radius: 6px;
    position: relative;top: 5px;left: -5px;
}
.menu_1ButShadow:hover {
    background: grey;
    border-radius: 6px;
    position: relative;top: 5px;left: -5px;
}
.menu_1But {
    position: relative;top: -5px;left: 5px;
    font-family: 'Saira', sans-serif;
    font-size: 14px;
    padding: 2px 6px;
    border: none;
    outline: none;
    background: lightgrey;
    border-bottom: 1px solid lightgrey;
    border-radius: 6px;
    transition: 0.2s;
}
.menu_1But:hover {
    position: relative;top: -2px;left: 2px;
    cursor: pointer;
    filter: brightness(120%);
}
.menu_1But:active {
    position: relative;top: 0;left: 0;
    filter: brightness(150%);
}
.markerbut {
    background: rgb(178, 245, 178);
}
@media screen and (max-width: 900px) {
    .menu_1But {
        font-size: 14px;
        padding: 1px 2px;
    }
}
@media screen and (max-width: 500px) {
    .menu_1But {
        font-size: 11px;
        padding: 1px 2px;
    }
}