:root {
    --mapScaling: 0.7;
    --mapTranslateY: -80px;
    --regDepButTopPos: 180px;
    --regDepButRightPos: 20vw;
}
@media screen and (min-width: 1600px) {
    :root {
        --mapScaling: 1.2;
        --mapTranslateY: 50px;
        --regDepButTopPos: 200px;
        --regDepButRightPos: 30vw;
    }
}
@media screen and (max-width: 600px) {
    :root {
        --mapScaling: 0.5;
        --mapTranslateY: -200px;
        --regDepButTopPos: 170px;
        --regDepButRightPos: 8vw;
    }
}
.backToMap {
    z-index: 5000;
    background: orange;
    font-size: 18px;
}
.backToMap:hover {
    cursor: pointer;
    filter: brightness(120%);
}
.mapContainer {
    width: 100vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.mapContent {
    transform:
        scale(var(--mapScaling))
        translateY(var(--mapTranslateY));
}

.regionZone {
    transition: 0.2s;
}
.regionZone:hover {
    cursor: pointer;
    filter: brightness(120%);
}
.regionZone:active {
    fill: yellow;
}
.selectedCardZone {
    min-width: 300px;
    font-size: 20px;
    font-family: 'Saira';
    z-index: 200;
    outline: none;
    border: none;
    background: linear-gradient(
        lightyellow,
        lightgrey,
        grey
    );
}
.reg_dep_but {
    z-index: 200;
    outline: none;
    border: none;
    position: absolute;
    top: var(--regDepButTopPos);
    right: var(--regDepButRightPos);
    background: rgb(233, 231, 139);
    font-size: 10px;
    font-family: 'Saira';
    padding: 2px 6px;
    border-radius: 6px;
    transition: 0.2s;
}
.reg_dep_but:hover {
    cursor: pointer;
    filter: brightness(110%);
    letter-spacing: 0.05em;
}
.reg_dep_but:active {
    filter: brightness(80%);
    letter-spacing: 0;
    transform: (scale(0.8));
}
@keyframes changeCard {
    0%{transform: scale(0.5) translateY(var(--mapTranslateY));}
    100%{transform: scale(var(--mapScaling)) translateY(var(--mapTranslateY));}
}