:root {
    --returnButTop: -65px;
    --inputBoxWidth: 500px;
    --inputFontSize: 18px;
}
@media screen and (min-width: 580px) and (max-width: 900px) {
    :root {
        --returnButTop: -45px;
        --inputBoxWidth: 500px;
        --inputFontSize: 18px;
    }
}
@media screen and (max-width: 580px) {
    :root {
        --returnButTop: -30px;
        --inputBoxWidth: 300px;
        --inputFontSize: 14px;
    }
}
.returnToHome {
    position: relative;
    left: calc(var(--inputBoxWidth) - 40px);
    top: var(--returnButTop);
    font-size: var(--inputFontSize);
    color: red;
}
.returnToHome:hover {
    cursor: pointer;
}
.phoneNb {
    width: 100%;
    height: 15px;
    text-align: center;
    position: relative;
    top: -30px;
}
.phoneNb > span {
    font-size: 20px;
}
.confirmationMessage {
    border: 1px solid grey;
    border-radius: 10px;
    position: relative;
    top: 120px;
    text-align: center;
    padding: 10px 0;
    margin: 0 5px;
    background: linear-gradient(rgb(47, 192, 47), lightgreen, rgb(47, 192, 47));
    font-size: 20px;
    font-weight: 800;
}
.selectedContent {
    font-family: 'Alegreya Sans SC', sans-serif;
    background: lightgrey;
    border-radius: 8px;
    box-shadow: 0 0 6px 6px rgb(158, 158, 158);
    width: calc(var(--inputBoxWidth) + 40px);
    margin-top: 40px;
    animation: dispPage 0.7s linear;
}
.inputBox {
    width: var(--inputBoxWidth);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}
.inputBox > span, .inputBox > label {
    font-size: var(--inputFontSize);
    margin-left: 20px;
}
input[type="checkbox"] {
    transform: scale(2);
    margin-right: 100px;
}
.inputBox > input {
    font-family: 'Saira', sans-serif;
    outline: none;
    border: 0;
    background: rgba(200, 202, 202, 0.6);
    border-bottom: 1px solid lightyellow;
    font-size: var(--inputFontSize);
}
.inputBox > input:focus {
    outline: none;
    border: 0;
    background: rgba(248, 248, 248, 0.6);
    border-bottom: 1px solid lightyellow;
    font-size: var(--inputFontSize);
}
.areaBoxContainer {
    width: var(--inputBoxWidth); 
    text-align: center;
}
.areaBox {
    font-family: 'Saira', sans-serif;
    font-size: var(--inputFontSize);
    margin-left: 30px;
    width: calc(var(--inputBoxWidth) - 40px);
}
.bottomButton_1 {
    margin: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.bottomButton_2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
#subButton {
    font-size: 16px;
    background: rgb(206, 247, 206);
    outline: none;
    border: 1px solid grey;
    border-radius: 4px;
    transition: 0.2s;
}
#subButton:hover {
    cursor: pointer;
    transform: scale(1.05);
    background: lightgreen;
}
#subButton:active {
    cursor: pointer;
    transform: scale(0.95);
    background: yellow;
}