.centerH2 {
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
}
.centerH2 > h2 {
    font-size: 20px;
    text-align: center;
}
@media screen and (max-width: 700px) {
    .centerH2 > h2 {
        font-size: 14px;
        max-width: 70vw;
    }
}
.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.selectorToolContainer {
    z-index: 1;
    position: absolute;
    top: 30vh;
    height: 100px;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.selectorToolContainer > div {
    margin: 2vw;
    font-size: 48px;
    transition: 0.2s;
}
@media screen and (max-width: 700px) {
    .selectorToolContainer {
        top: 26vh;
    }
    .selectorToolContainer > div {
        font-size: 28px;
    }
}
.selectorToolContainer > div:hover  {
    cursor: pointer;
    transform: scale(1.2);
}
.selectorToolContainer > div:active  {
    color: yellow;
    transform: scale(0.95);
}
.imageContainer {
  width: 100vw;
  height: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profilImage {
    max-height: 50vh;
    width: auto;
    max-width: 90vw;
    transition: 0.4s;
}
.h2Title {
    animation: dispH2Title 0.8s ease-in-out;
}
@keyframes dispH2Title {
    0%{transform: translateX(75vw); opacity: 0;}
    100%{transform: translateX(0); opacity: 1;}
}
.pythonContent > p {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    text-align: center;
}
@keyframes dispText {
    0%{transform: rotateX(90deg); opacity: 0;}
    100%{transform: rotateX(0); opacity: 1;}
}
@keyframes hideText {
    0%{transform: rotateX(0); opacity: 1;}
    25%{transform: rotateX(45deg); opacity: 0;}
    100%{transform: rotateX(90deg); opacity: 0;}
}
@media screen and (max-width: 700px) {
    .pythonContent > p {
        font-size: 14px;
    }
}