:root {
    --bltPubH2FontSize: 22px;
    --bltPubH3FontSize: 22px;
    --bltPubH4FontSize: 20px;
    --bltPubFontSize: 18px;
    
}
@media screen and (min-width: 600px) and (max-width: 800px) {
    :root {
        --bltPubH2FontSize: 18px;
        --bltPubH3FontSize: 22px;
        --bltPubH4FontSize: 20px;
        --bltPubFontSize: 16px;
    }
}
@media screen and (max-width: 600px) {
    :root {
        --bltPubH2FontSize: 16px;
        --bltPubH3FontSize: 14px;
        --bltPubH4FontSize: 14px;
        --bltPubFontSize: 14px;
    }
}
.bltContainer {
    width: 94vw;
    margin-left: 3vw;
    background: rgba(211, 211, 211, 0.4);
    overflow-y: auto;
}
.bltwebappLink {
    z-index: 100;
    background: white;
    padding: 2px 6px;
    border: 2px solid rgb(85, 85, 85);
    border-radius: 3px;
    position: absolute;
    top: -15px;
    left: 15px;
 }
.bltPubContainer {
    width: 96vw;
    max-width: 1000px;
    margin-top: 35px;
    height: 500px;
    border: 2px solid rgb(85, 85, 85);
    border-radius: 6px;
    background: linear-gradient(
        rgb(223, 223, 223), 
        rgb(255, 255, 255), 
        rgb(223, 223, 223)
    );
    opacity: 0.7;
}
.bltPubContent {
    padding: 0 20px;
}
.bltPubContent > p {
    font-family: 'Montserrat', sans-serif;
    font-size: var(--bltPubFontSize);
    text-align: justify;
}
.bltContactLink {
    width: 40px;
    position: relative;
    top: 10px;
    left: -10vw;
    transition: 0.2s;
}
.bltContactLink:hover {
    cursor: pointer;
    filter: brightness(110%);
}
/* *** formule demo *** START *** */

.adminModeBut > p {
    font-family: 'Montserrat', sans-serif;
    font-size: var(--bltPubFontSize);
    text-align: justify;
    margin: 4px;
}

/* *** formule demo *** END *** */
/* *** input file *** STAR *** */
.fileInputBut {
  background: rgb(140, 150, 238);
  font-family: 'Titillium Web', sans-serif;
  font-size: 14px;
  border-radius: 50px;
  box-shadow: 5px 5px 10px grey;
  width: 300px;
  outline: none;
}
::-webkit-file-upload-button {
  color: rgb(23, 24, 74);
  background: rgba(99, 108, 240, 0.6);
  color: lightyellow;
  border: none;
  border-radius: 50px;
  box-shadow: 1px 0 1px 1px grey;
  border: 1px solid grey;
}
/* *** input file *** END *** */
.bltChangeImg {
    margin-bottom: 10px;
    border-bottom: 1px solid grey;
    background: linear-gradient(rgba(253, 253, 253, 0.2),rgba(253, 253, 253, 0.2),rgba(253, 253, 253, 0.2), rgb(190, 190, 190));
}
.inputUnit {
    padding: 5px 0 8px 0;
    background: linear-gradient(rgba(253, 253, 253, 0.2),rgba(253, 253, 253, 0.4),rgba(253, 253, 253, 0.6), rgb(190, 190, 190));
    text-align: center;
}
.formuleContainer {
    margin: 30px;
    width: 600px;
    min-width: 600px;
    height: 300px;
    min-height: 300px;
    border: 1px solid grey;
    border-radius: 4px;
}
.formuleContainerSmall {
    margin: 30px;
    width: 300px;
    min-width: 300px;
    height: 600px;
    min-height: 600px;
    border: 1px solid grey;
    border-radius: 4px;
}
.formuleImageContainer {
    width: 300px;
    max-width: 300px;
    height: 300px;
    max-height: 300px;
    overflow: hidden;
}
.formuleImage {
    min-width: 300px;
    min-height: 300px;
    background-size: contain;
}
.formuleImage:hover {
    cursor: grab;
    filter: brightness(110%);
}
.textContainer {
    width: 300px;
    min-width: 300px;
    height: 300px;
    min-height: 300px;
}
.formuleLabelStyle {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 10px;
}
.textAreaStyle {
    resize: none;
    font-family: 'Montserrat', sans-serif;
    filter: brightness(120%);
    outline: none;
    border: none;
}
.formuleTitleTextAreaStyle {
    height: 1.3em;
    font-size: 18px;
}
.formuleTextTextAreaStyle {
    font-size: 14px;
}
.formuleTitleStyle, .formuleTextStyle, .formulePostStyle  {
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
    padding: 0 8px;
}
.formuleTitleStyle {
    font-size: 20px;
    font-weight: 600;
}
.formuleTextStyle {
    font-size: 16px;
    font-weight: 500;

}
.formulePostStyle {
    font-size: 14px;
    font-weight: 500;

}
.contactBlt {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    margin: 0 0 50px 0;
    border: 1px solid grey;
    padding: 10x 25px;
    max-width: 120px;
    border-radius: 6px;
    background-image: repeating-linear-gradient(135deg, rgba(0,0,0,0.03) 0px, rgba(0,0,0,0.03) 2px,transparent 2px, transparent 4px),linear-gradient(45deg, rgba(255,231,205, 0.57),rgba(166,255,155, 0.48));
    filter: brightness(120%);
    transition: 0.25s;
}
.contactBlt:hover {
    cursor: pointer;
    filter: brightness(150%);
}
.imgLoadingMessage {
    width: 100%;
    position: absolute;
    top: 50%;
    background: lightyellow;
    color: red;
    padding: 4px 6px;
    text-align: justify;
}
/* *** 3d checkbox effect *** START ***  */
:root {
    --checkWidth: 45px;
    --checkHeight: calc(var(--checkWidth)/3);
    --checkedBglinearG1: #c9d7f5;
    --checkedBglinearG2: #ffffff;
    --uncheckedBglinearG1: rgb(218, 168, 135);
    --uncheckedBglinearG2: #3f49b7;
    --uncheckedBglinearG3: rgb(17, 20, 44);
    --uncheckedBgvariation: rgb(164, 238, 238);
}
.bltCheckbox { 
    position: relative;
    left: 30px;
    width:  var(--checkWidth);
    height: var(--checkHeight);
    -webkit-appearance: none;
    background: linear-gradient(0deg, var(--uncheckedBglinearG1), var(--uncheckedBglinearG3));
    outline: none;
    border-radius: calc(var(--checkHeight)/2);
    box-shadow: 0 0 2px var(--checkedBglinearG1),
                0 0 0 4px var(--uncheckedBgvariation),
                0 0 0 5px var(--uncheckedBgvariation),
                inset 0 0 10px rgba(0,0,0,1),
                0 5px 20px rgba(0,0,0,.5),
                inset 0 0 15px rgba(0,0,0,.2) ;
}
.bltCheckbox:hover {
    cursor: pointer;
    filter: brightness(110%);
}
.bltCheckbox:checked {
    background: linear-gradient(0deg, var(--checkedBglinearG1), var(--checkedBglinearG2));
}
.bltCheckbox:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(var(--checkWidth)/3*2);
    height: var(--checkHeight);
    background: linear-gradient(0deg, var(--uncheckedBglinearG1), var(--uncheckedBglinearG2));
    border-radius: calc(var(--checkHeight)/2);
    box-shadow: 0 0 0 1px #232323;
    transform: scale(.98,.96);
    transition: .5s;

}
.bltCheckbox:checked:before {
    left: calc(var(--checkWidth)/3);
}
input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: calc(50% - calc(var(--checkWidth)/10)/2);
    left: calc(var(--checkWidth)/2);
    width: calc(var(--checkWidth)/10);
    height: calc(var(--checkWidth)/10);
    background: linear-gradient(0deg, var(--uncheckedBglinearG2), var(--uncheckedBglinearG3));
    border-radius: 50%;
    transition: .5s;
}
.bltCheckbox:checked:after {
    background: var(--checkedBglinearG2);
    left: calc(var(--checkWidth)*0.8);
    box-shadow: 0 0 5px var(--checkedBglinearG1), 0 0 15px var(--checkedBglinearG2);
}
/* *** 3d checkbox effect *** END ***  */