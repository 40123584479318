.maskLoader {
    transform: scale(0);
    /* transition: 0.2s linear; */
}
#loadingPage {
    position: absolute;
    top: 0; left: 0;
    width: 100vw;
    height: 100%;
    background-image: radial-gradient(circle at center center, rgba(33,33,33,0),rgb(29,29,63)),repeating-linear-gradient(135deg, rgb(29,29,63) 0px, rgb(29,29,63) 1px,transparent 1px, transparent 4px),repeating-linear-gradient(45deg, rgb(67,71,88) 0px, rgb(67,71,88) 5px,transparent 5px, transparent 6px),linear-gradient(90deg, rgb(29,29,63),rgb(29,29,63));
    z-index: 1000;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: hypnose 1.5s linear infinite;
}
#loadingTitleh1 {
    position: absolute;
    top: 10vh;
    left: 5vw;
    transform: scale(0.9);
    color: rgb(228, 228, 176);
}
#loadingTitleh2 {
    position: absolute;
    top: 18vh;
    left: 12vw;
    color: rgb(216, 216, 157);
}
@keyframes hypnose {
    0%{filter: brightness(102%);}
    40%{filter: brightness(100%);}
    50%{filter: brightness(102%);}
    90%{filter: brightness(100%);}
}
.lettre {
    margin-top: 100px;
    color: #484848;
    margin-bottom: 150px;
    animation: flash 1.2s linear infinite;
}
@media screen and (min-width: 580px) {
    .lettre {
    font-size: 50px;
    letter-spacing: 15px;
    }
}
@media screen and (max-width: 580px) {
    .lettre {
        font-size: 24px;
        letter-spacing: 5px;
    }
}
@keyframes flash {
    0% {
        color: rgb(0, 13, 251);
    }
    40% {
        color: rgb(0, 13, 251);
    }
    60% {
        color: rgb(168, 181, 225);
    }
    80% {
        color: rgb(0, 13, 251);
    }
    100% {
        color: rgb(0, 13, 251);
    }
}
.lettre:nth-child(1){
    animation-delay: 0.1s;
}
.lettre:nth-child(2){
    animation-delay: 0.2s;
}
.lettre:nth-child(3){
    animation-delay: 0.3s;
}
.lettre:nth-child(4){
    animation-delay: 0.4s;
}
.lettre:nth-child(5){
    animation-delay: 0.5s;
}
.lettre:nth-child(6){
    animation-delay: 0.6s;
}
.lettre:nth-child(7){
    animation-delay: 0.7s;
}
.lettre:nth-child(8){
    animation-delay: 0.8s;
}
.lettre:nth-child(9){
    animation-delay: 0.9s;
}
.lettre:nth-child(10){
    animation-delay: 1s;
}